.info {
  padding: 2rem;
  max-width: 960px;
  margin: auto;
  background-color: white;
  z-index: 100;
}

.container {
  position: relative;
  margin: 0 auto;
}

.backgroundImage {
  width: 100%;
  height: 100%;
}
