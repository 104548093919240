html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.container {
  position: relative;
  margin: 0 auto;
}

.mainBlock {
  padding: 2rem;
  max-width: 960px;
  margin: auto;
  background-color: white;
  z-index: 100;
}

.shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.imgLeft {
  max-width: 100%;
  max-height: 100%;
}

.imgRight {
  max-width: 100%;
  max-height: 100%;
}

.BlockTEAL {
  background-color: rgb(167, 252, 255);
  border: solid 2px #efeb55; /* Simplified border shorthand */
  padding: 1rem; /* Consistent padding */
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.tealBlockText p {
  font-size: 1.4em;
  margin-bottom: 2.2em;
}

.tealBlockText ul {
  font-size: 1.2em;
  max-width: 390px;
  margin: auto;
  text-align: center;
  list-style-position: outside;
  padding-left: 1rem;
  list-style-type: none;
}

.block2 {
  margin-top: 1rem;
  border: solid 1px; /* Simplified border shorthand */
}

.finalTextBlock {
  font-size: 1.2em;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin: 2rem;
}

.headshot1 {
  width: 400px;
  height: 400px;
  object-fit: cover;
  object-position: 20% 30%;
  margin: auto;
  display: block;
  padding-bottom: 1rem;
}

.outerTealBlock {
  background-color: rgb(167, 252, 255);
  padding: 2rem;
}

.Block1 {
  padding: 1rem;
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.Block1Text {
  font-size: 1.4em;
}
